"use client";
import React, { useEffect } from "react";
import Image from "next/image";
import Link from "next/link";
import HeaderMenus from "./header-menus";
import useSticky from "@/hooks/use-sticky";
import MobileOffcanvas from "@/components/offcanvas/mobile-offcanvas";

declare global {
  interface Window {
    googleTranslateElementInit: () => void;
    google: {
      translate: {
        TranslateElement: new (
          options: TranslateElementOptions,
          elementId: string
        ) => void;
      };
    };
  }
}

interface TranslateElementOptions {
  pageLanguage: string;
  includedLanguages?: string;
}

const HeaderOne = () => {
  const { sticky, headerRef, headerFullWidth } = useSticky();
  const [openOffCanvas, setOpenOffCanvas] = React.useState(false);
  const [selectedLanguage, setSelectedLanguage] = React.useState("en");

  useEffect(() => {
    headerFullWidth();

    const savedLanguage = localStorage.getItem("selectedLanguage") || "en";
    setSelectedLanguage(savedLanguage);

    const hideGoogleTranslateToolbar = () => {
      const style = document.createElement("style");
      style.type = "text/css";
      style.innerHTML = `
        .goog-te-banner-frame.skiptranslate { display: none !important; }
        .goog-te-banner-frame { display: none !important; }
        .goog-te-gadget-icon { display: none !important; }
        body { top: 0px !important; }
      `;
      document.head.appendChild(style);
    };

    hideGoogleTranslateToolbar();

    const addGoogleTranslateScript = () => {
      if (
        !document.querySelector(
          'script[src="https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"]'
        )
      ) {
        const script = document.createElement("script");
        script.type = "text/javascript";
        script.async = true;
        script.src =
          "https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit";
        document.body.appendChild(script);
      } else {
        if (window.google && window.google.translate) {
          window.googleTranslateElementInit();
        }
      }
    };

    const googleTranslateElementInit = () => {
      new window.google.translate.TranslateElement(
        {
          pageLanguage: "",
          includedLanguages: "en,fr,ar,tr",
        },
        "google_translate_element"
      );

      // Set the default language to English
      const googleTranslateElement = document.querySelector(
        ".goog-te-combo"
      ) as HTMLSelectElement;
      if (googleTranslateElement) {
        googleTranslateElement.value = "en";
        googleTranslateElement.dispatchEvent(new Event("change"));
      }
    };

    window.googleTranslateElementInit = googleTranslateElementInit;
    addGoogleTranslateScript();

    const observer = new MutationObserver(() => {
      const banner = document.querySelector(".goog-te-banner-frame");
      if (banner) {
        hideGoogleTranslateToolbar();
        observer.disconnect();
      }
    });

    observer.observe(document.body, { childList: true, subtree: true });

    const intervalId = setInterval(() => {
      const banner = document.querySelector(".goog-te-banner-frame");
      if (banner) {
        hideGoogleTranslateToolbar();
      }
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  const handleTranslate = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const language = event.target.value;
    setSelectedLanguage(language);
    localStorage.setItem("selectedLanguage", language);
    const googleTranslateElement = document.querySelector(
      ".goog-te-combo"
    ) as HTMLSelectElement;
    if (googleTranslateElement) {
      googleTranslateElement.value = language;
      googleTranslateElement.dispatchEvent(new Event("change"));
    }
    // Hide the toolbar after changing the language
    const hideGoogleTranslateToolbar = () => {
      const style = document.createElement("style");
      style.type = "text/css";
      style.innerHTML = `
        .goog-te-banner-frame.skiptranslate { display: none !important; }
        .goog-te-banner-frame { display: none !important; }
        .goog-te-gadget-icon { display: none !important; }
        body { top: 0px !important; }
      `;
      document.head.appendChild(style);
    };
    hideGoogleTranslateToolbar();
  };

  return (
    <>
      <header className="tp-header-height" ref={headerRef}>
        <div
          id="header-sticky"
          className={`tp-header-area tp-inner-header-2-bg tp-header-mob-space tp-transparent pl-60 pr-60 z-index-9 ${sticky ? "header-sticky" : ""}`}
        >
          <div className="container">
            <div className="row align-items-center">
              <div className="col-xl-2 col-lg-2 col-6">
                <div className="tp-header-logo">
                  <a className="logo-1" href="/">
                    <Image
                      src="/assets/img/logo/fulllogo.png"
                      alt="logo"
                      width={85}
                      height={26}
                    />
                  </a>
                  <a className="logo-2" href="/">
                    <Image
                      src="/assets/img/logo/logo-white.png"
                      alt="logo"
                      width={85}
                      height={26}
                    />
                  </a>
                </div>
              </div>
              <div className="col-xl-8 col-lg-8 d-none d-xl-block">
                <div className="tp-header-menu header-main-menu text-center">
                  <nav className="tp-main-menu-content">
                    <HeaderMenus />
                  </nav>
                </div>
              </div>
              <div className="col-xl-2 col-lg col-6">
                <div className="tp-header-bar text-end">
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "15px",
                    }}
                  >
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <i
                        className="fas fa-globe"
                        style={{ marginLeft: "5px", color: "#000000" }}
                      ></i>
                      <div
                        id="google_translate_element"
                        className="google-translate-element responsive-hide custom-google-translate"
                      ></div>
                    </div>
                    <button
                      className="tp-offcanvas-open-btn"
                      onClick={() => setOpenOffCanvas(true)}
                    >
                      <span></span>
                      <span></span>
                      <span></span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>

      <MobileOffcanvas
        openOffcanvas={openOffCanvas}
        setOpenOffcanvas={setOpenOffCanvas}
      />
    </>
  );
};

export default HeaderOne;