import b_m_1 from "@/assets/img/inner-blog/servicesImg/JawSurgeries.jpeg";
import b_m_2 from "@/assets/img/inner-blog/servicesImg/CosmeticDentistry.jpeg";
import b_m_3 from "@/assets/img/inner-blog/servicesImg/Endodontics.jpeg";
import b_m_4 from "@/assets/img/inner-blog/servicesImg/DentalImplants.jpeg";
import b_m_5 from "@/assets/img/inner-blog/servicesImg/Periodontics.jpeg";
import b_m_6 from "@/assets/img/inner-blog/servicesImg/Dentalprostheses.jpeg";
import b_m_7 from "@/assets/img/inner-blog/servicesImg/GummySmile.jpeg";
import ser_img_1 from "@/assets/img/inner-service/service/service1.jpeg";

import icon_1 from "/public/assets/img/inner-blog/servicesImg/icons/implant.png";
import icon_2 from "/public/assets/img/inner-blog/servicesImg/icons/jaw.png";
import icon_3 from "/public/assets/img/inner-blog/servicesImg/icons/Endodontie.png";
import icon_4 from "/public/assets/img/inner-blog/servicesImg/icons/cosmétique.png";
import icon_5 from "/public/assets/img/inner-blog/servicesImg/icons/Periodontics.png";
import icon_6 from "/public/assets/img/inner-blog/servicesImg/icons/prostheses.png";
import icon_7 from "/public/assets/img/inner-blog/servicesImg/icons/smile.png";

import b_1 from "@/assets/img/inner-blog/servicesImg/jawbanner.jpeg";
import b_2 from "@/assets/img/inner-blog/servicesImg/Cosmétiquebanner.jpeg";
import b_3 from "@/assets/img/inner-blog/servicesImg/Endodontiebanner.jpeg";
import b_4 from "@/assets/img/inner-blog/servicesImg/implantsbanner.jpeg";
import b_5 from "@/assets/img/inner-blog/servicesImg/Periodonticsbanner.jpeg";
import b_6 from "@/assets/img/inner-blog/servicesImg/prosthesesbanner.jpeg";
import b_7 from "@/assets/img/inner-blog/servicesImg/Gummybanner.jpeg";

import { IBlogDT } from "@/types/blog-d-t";

export const services: IBlogDT[] = [
  {
    id: 1,
    img: b_m_1,
    title: "Jaw surgeries",
  },
  {
    id: 2,
    img: b_m_2,
    title: "Dentisterie cosmétique",
  },
  {
    id: 3,
    img: b_m_3,
    title: "Endodontie",
  },
  {
    id: 4,
    img: b_m_4,
    title: "Dental implants",
  },
  {
    id: 5,
    img: b_m_5,
    title: "Periodontics",
  },
  {
    id: 6,
    img: b_m_6,
    title: "Dental prostheses",
  },
  {
    id: 7,
    img: b_m_7,
    title: "Gummy smile",
  },
];

export const blog_data: IBlogDT[] = [...services];

export const service_data = [
  {
    id: 1,
    img: b_m_1,
    img1: b_1,
    img2: ser_img_1,
    subtitle: "ray dent clinic",
    title: "Jaw Surgeries",
    subtitle1: "TREATEAMENT TIME: 3-6 days",
    icon: icon_2,
    text: "Jaw surgeries are procedures performed to correct abnormalities of the jaw bones and associated structures. These surgeries are typically performed by oral and maxillofacial surgeons and may be recommended to address a variety of functional and aesthetic concerns.",
    text2: "Comprehensive Solutions for Complex Dental Issues",
    text3:
      "Jaw surgeries, also known as orthognathic surgeries, are essential for correcting structural and functional issues of the jaw. These procedures are performed to improve the alignment of the jaw and teeth, which can significantly impact speech, chewing, and overall oral health.",
    lists1: [
      "Malocclusion: Misalignment of teeth that affects bite and jaw function, causing discomfort or difficulty in eating.",
      "Facial Asymmetry: Uneven jaw growth leading to cosmetic concerns, often impacting self-esteem.",
      "Trauma: Injuries to the jaw requiring surgical intervention for restoration and proper alignment.",
      "Sleep Apnea: Jaw surgery can reposition the jaw to open airways, improving breathing during sleep.",
    ],
    text4: "Types of Jaw Surgeries:",
    lists: [
      "Orthognathic Surgery: Involves repositioning the upper (maxilla) or lower (mandible) jaw to align teeth correctly and improve bite. This is often accompanied by braces to achieve the desired alignment.",
      "TMJ Surgery: Focuses on the temporomandibular joint to alleviate pain and restore function, usually recommended when non-surgical treatments fail.",
      "Jaw Reconstruction: Restores bone structure and function following trauma, tumor removal, or congenital defects. This may involve grafting bone from other parts of the body.",
    ],
    text5: "Benefits:",
    lists2: [
      "Enhanced chewing and speech capabilities through improved jaw alignment.",
      "Aesthetically pleasing facial symmetry, contributing to a more attractive appearance.",
      "Relief from chronic pain and discomfort related to jaw disorders, leading to improved quality of life.",
      "Increased self-confidence due to improved facial appearance.",
    ],
    text6: "Recovery and Aftercare:",
    lists3: [
      "Post-surgery, patients may experience swelling, bruising, and discomfort, which can be managed with prescribed medications.",
      "A soft-food diet is typically recommended during the initial healing phase, transitioning to a regular diet as healing progresses.",
      "Follow-up visits are essential to monitor recovery and jaw function. Patients should report any complications, such as increased pain or signs of infection, immediately.",
      "Physical therapy may be advised to improve jaw mobility and strength, helping to restore normal function more quickly.",
    ],
    text7: "Jaw Surgeries Healing Stages",
    text8:
      "The healing process following jaw surgery involves several stages, primarily focusing on the stabilization of the jawbone and soft tissues. The duration of recovery varies depending on the type of surgery performed, and patients may be advised to follow specific dietary guidelines and lifestyle changes to promote healing.",
    lists4: [
      {
        stage: "Surgical Procedure",
        description:
          "The surgeon repositions the jaw and secures it with plates and screws, if necessary.",
        duration: "3-6 hours",
      },
      {
        stage: "Initial Recovery",
        description:
          "Patients spend a few hours in recovery, monitored for pain and swelling.",
        duration: "1-2 days",
      },
      {
        stage: "Soft Tissue Healing",
        description:
          "Initial healing of soft tissues begins, with swelling peaking within 48 hours.",
        duration: "1-2 weeks",
      },
      {
        stage: "Bone Healing",
        description:
          "The jawbone begins to heal and stabilize, requiring a soft diet to avoid strain on the jaw.",
        duration: "6-12 weeks",
      },
      {
        stage: "Full Recovery",
        description:
          "Follow-up visits are conducted to assess healing, and full functionality is gradually restored.",
        duration: "3-6 months",
      },
    ],
  },
  {
    id: 2,
    img: b_m_2,
    img1: b_2,
    img2: ser_img_1,
    subtitle: "ray dent clinic",
    title: "Dentisterie Cosmétique",
    subtitle1: "TREATEAMENT TIME: 3-6 days",
    icon: icon_4,
    text: "",
    text2: "Transforming Smiles for Enhanced Confidence",
    text3: "Cosmetic dentistry focuses on enhancing the appearance of teeth, gums, and smiles. It encompasses various procedures that can improve aesthetic issues, resulting in a radiant smile that boosts self-esteem and confidence.",
    lists1: [
      "Teeth Whitening: Professional treatments or at-home kits designed to remove stains and discoloration for a brighter smile. Options include in-office whitening and take-home kits.",
      "Veneers: Custom-made porcelain or composite shells applied to the front surface of teeth to cover imperfections such as chips, gaps, or discoloration, providing a natural look.",
      "Bonding: A tooth-colored resin applied to teeth to correct chips, cracks, or gaps, providing a natural appearance and can be done in one visit."
    ],
    text4: "Additional Treatments:",
    lists: [
      "Invisalign: Clear aligners that straighten teeth discreetly without traditional metal braces, allowing for better oral hygiene and comfort.",
      "Contouring and Reshaping: Minor reshaping of teeth to improve their size and shape, correcting small imperfections for a more symmetrical smile.",
      "Gum Reshaping: Adjusting the gum line to create a more balanced and attractive smile, often performed in conjunction with other cosmetic procedures.",
    ],
    text5: "Benefits:",
    lists2: [
      "Improved self-esteem and confidence in personal and social interactions.",
      "Enhanced oral health, as cosmetic procedures often involve necessary repairs, preventing further dental issues.",
      "Long-lasting results with proper care and maintenance, making cosmetic enhancements a worthwhile investment.",
    ],
    text6: "Recovery and Aftercare:",
    lists3: [
      "Most cosmetic procedures have minimal downtime, allowing patients to resume normal activities quickly.",
      "Patients are advised to avoid certain foods or beverages that may stain teeth after whitening treatments.",
      "Regular dental hygiene practices and follow-up appointments are essential to maintain results, especially for procedures like whitening and bonding.",
    ],
    text7: "Cosmetic Dentistry Healing Stages",
    text8: "The healing process for cosmetic dental procedures varies depending on the treatment performed. Most cosmetic procedures have minimal recovery time, but some may require special care to ensure optimal results.",
    lists4: [
      {
        stage: "Initial Treatment",
        description: "The cosmetic procedure is performed, such as whitening, bonding, or veneer placement.",
        duration: "1-2 hours",
      },
      {
        stage: "Sensitivity Period",
        description: "Patients may experience some tooth sensitivity as the materials settle, especially after whitening.",
        duration: "1-3 days",
      },
      {
        stage: "Aesthetic Adjustment",
        description: "Initial adjustments may be necessary for veneers or bonding to ensure a perfect fit.",
        duration: "1-2 weeks",
      },
      {
        stage: "Full Aesthetic Integration",
        description: "The final results are established as the materials integrate fully with natural teeth.",
        duration: "2-4 weeks",
      },
    ],
  },
  {
    id: 3,
    img: b_m_3,
    img1: b_3,
    img2: ser_img_1,
    subtitle: "ray dent clinic",
    title: "Endodontie",
    subtitle1: "TREATEAMENT TIME: 3-6 days",
    icon: icon_3,
    text: "",
    text2: "Specialized Care for Tooth Preservation",
    text3: "Endodontics is a dental specialty focusing on the treatment of dental pulp and surrounding tissues. Root canal therapy is the most common procedure performed, aimed at saving natural teeth that have become infected or damaged.",
    lists1: [
      "Root Canal Therapy: This procedure removes infected pulp, cleans the root canal, and seals it to prevent further infection. It is essential for saving a tooth that would otherwise need to be extracted.",
      "Endodontic Retreatment: A second chance for previously treated teeth that have developed new issues, involving re-cleaning and sealing of the root canal.",
      "Apicoectomy: Surgical removal of the tooth root tip to treat persistent infections that do not respond to conventional root canal therapy.",
    ],
    text4: "Common Indications for Endodontic Treatment:",
    lists: [
      "Severe toothache or sensitivity to hot and cold that does not subside.",
      "Swelling or tenderness in the gums, often accompanied by visible signs of infection.",
      "Darkening of the tooth, indicating potential pulp damage or necrosis."
    ],
    text5: "Benefits:",
    lists2: [
      "Preservation of natural teeth, preventing the need for extractions and subsequent restorations like bridges or dentures.",
      "Relief from pain and discomfort associated with pulp infections, allowing patients to resume normal activities.",
      "Restoration of tooth function and appearance with crowns after treatment, enhancing aesthetics.",
    ],
    text6: "Recovery and Aftercare:",
    lists3: [
      "Post-treatment discomfort can be managed with over-the-counter pain relief medications or prescribed analgesics.",
      "Patients are advised to maintain good oral hygiene to support healing and prevent reinfection.",
      "Regular check-ups are crucial to monitor the health of the treated tooth and surrounding tissues, ensuring long-term success.",
    ],
    text7: "Endodontics Healing Stages",
    text8: "Healing after endodontic treatment, particularly root canal therapy, typically involves a few stages. Most patients experience minimal downtime, with a focus on managing discomfort and preventing infection.",
    lists4: [
      {
        stage: "Root Canal Procedure",
        description: "The dentist removes the infected pulp and cleans the root canal, sealing it afterward.",
        duration: "1-2 hours",
      },
      {
        stage: "Initial Recovery",
        description: "Patients may experience some discomfort, which can be managed with pain relievers.",
        duration: "1-3 days",
      },
      {
        stage: "Healing of Tissues",
        description: "The surrounding tissues begin to heal as inflammation subsides; dental crowns may be placed.",
        duration: "1-2 weeks",
      },
      {
        stage: "Follow-Up Visit",
        description: "A check-up is scheduled to ensure the success of the treatment and proper healing.",
        duration: "2-4 weeks",
      },
    ],
  },
  {
    id: 4,
    img: b_m_4,
    img1: b_4,
    img2: ser_img_1,
    subtitle: "ray dent clinic",
    title: "Dental implants",
    subtitle1: "TREATEAMENT TIME: 3-6 days",
    icon: icon_1,
    text: "",
    text2: "A Lasting Solution for Tooth Replacement",
    text3: "Dental implants provide a secure and long-lasting solution for tooth replacement, designed to look, feel, and function like natural teeth. With the use of titanium posts that bond to the jawbone, implants restore both aesthetics and functionality, supporting a confident smile.",
    lists1: [
      "Missing Teeth: Restores one or more lost teeth with a natural appearance.",
      "Bone Loss Prevention: Stimulates the jawbone, preventing bone deterioration that often occurs with missing teeth.",
      "Support for Dentures or Bridges: Offers stability for other dental appliances, reducing slippage."
    ],
    text4: "Types of Dental Implant Procedures:",
    lists: [
      "Single Tooth Implants: Replaces a single missing tooth with an implant-supported crown.",
      "Multiple Implants: Secures multiple teeth with implants supporting a bridge.",
      "All-on-4 Implants: A full arch restoration using four strategically placed implants to support a complete denture."
    ],
    text5: "Benefits:",
    lists2: [
      "Improved Functionality: Restores full chewing and speaking capabilities.",
      "Natural Look and Feel: Mimics natural teeth, enhancing appearance and comfort.",
      "Long-Term Solution: With proper care, implants can last a lifetime, providing a stable and reliable replacement.",
    ],
    text6: "Recovery and Aftercare:",
    lists3: [
      "Dietary Adjustments: Soft foods are recommended initially to allow the implant to heal undisturbed.",
      "Oral Hygiene Maintenance: Regular brushing and flossing are essential to keep implants and surrounding tissue healthy.",
      "Routine Check-Ups: Follow-up appointments help monitor the stability and longevity of the implant.",
    ],
    text7: "Dental Implant Healing Stages",
    text8: "The dental implant healing process progresses through several critical stages, from implant placement to full integration with the jawbone. Adhering to dietary guidelines and attending follow-up visits are essential to ensure successful osseointegration and stability.",
    lists4: [
      {
        stage: "Surgical Implant Placement",
        description: "The titanium implant is surgically placed into the jawbone under local anesthesia.",
        duration: "1-2 hours",
      },
      {
        stage: "Initial Recovery",
        description: "Swelling and soreness may occur; patients are advised to follow a soft diet.",
        duration: "3-7 days",
      },
      {
        stage: "Osseointegration",
        description: "The implant integrates with the surrounding bone, securing it as a stable foundation.",
        duration: "3-6 months",
      },
      {
        stage: "Abutment Placement",
        description: "The abutment, which supports the crown, is attached to the implant once healing is sufficient.",
        duration: "1-2 weeks",
      },
      {
        stage: "Crown Placement",
        description: "The final prosthetic crown is attached, completing the restoration for functional use.",
        duration: "1-2 hours",
      },
    ],
  },
  {
    id: 5,
    img: b_m_5,
    img1: b_5,
    img2: ser_img_1,
    subtitle: "ray dent clinic",
    title: "Periodontics",
    subtitle1: "TREATEAMENT TIME: 3-6 days",
    icon: icon_5,
    text: "",
    text2: "Essential Care for Gum Health",
    text3: "Periodontics is a specialized field of dentistry focusing on the prevention, diagnosis, and treatment of gum disease and conditions affecting the supporting structures of teeth. Healthy gums are vital for maintaining overall oral health and preventing tooth loss.",
    lists1: [
      "Gingivitis: Inflammation of the gums, often caused by plaque buildup, leading to redness, swelling, and bleeding.",
      "Periodontitis: Advanced gum disease that can lead to bone loss and tooth mobility, often requiring surgical intervention for treatment.",
      "Receding Gums: Loss of gum tissue exposing tooth roots, which can lead to sensitivity and decay, necessitating gum grafting procedures.",
    ],
    text4: "Common Periodontal Treatments:",
    lists: [
      "Scaling and Root Planing: A deep cleaning procedure that removes plaque and tartar from below the gum line, often performed under local anesthesia for patient comfort.",
      "Periodontal Surgery: Surgical interventions to treat advanced gum disease, including flap surgery to clean roots and bone grafting to restore lost tissue.",
      "Gum Grafting: Surgical procedures to restore receded gum tissue, improving gum health and aesthetics, often using tissue from the patient’s palate or donor sources.",
    ],
    text5: "Benefits:",
    lists2: [
      "Prevention of tooth loss and improved overall oral health through effective treatment of gum disease.",
      "Reduced risk of systemic diseases linked to periodontal health, such as heart disease and diabetes.",
      "Enhanced aesthetics through improved gum contouring and health, leading to a more attractive smile."
    ],
    text6: "Recovery and Aftercare:",
    lists3: [
      "Post-treatment care may involve specific oral hygiene practices and dietary adjustments to promote healing.",
      "Regular dental visits are essential for monitoring gum health and ensuring successful treatment outcomes, including maintenance cleanings.",
      "Patients are encouraged to maintain good oral hygiene practices to prevent recurrence of gum disease, including daily brushing and flossing.",
    ],
    text7: "Periodontics Healing Stages",
    text8: "The healing process after periodontal treatments, such as scaling and root planing or gum surgery, is crucial for restoring gum health and preventing further disease.",
    lists4: [
      {
        stage: "Initial Treatment",
        description: "Scaling and root planing are performed to remove plaque and tartar from below the gum line.",
        duration: "1-2 hours",
      },
      {
        stage: "Initial Healing",
        description: "The gums may be swollen and sensitive; patients should follow post-operative care instructions.",
        duration: "1-2 weeks",
      },
      {
        stage: "Gum Tissue Recovery",
        description: "Gums begin to heal and reattach to the teeth as inflammation decreases.",
        duration: "4-6 weeks",
      },
      {
        stage: "Full Periodontal Recovery",
        description: "Regular follow-up visits ensure gum health is restored, and further treatment is planned if needed.",
        duration: "3-6 months",
      },
    ],
  },
  {
    id: 6,
    img: b_m_6,
    img1: b_6,
    img2: ser_img_1,
    subtitle: "ray dent clinic",
    title: "Dental prostheses",
    subtitle1: "TREATEAMENT TIME: 3-6 days",
    icon: icon_6,
    text: "",
    text2: "Restoring Function and Aesthetics",
    text3: "Dental prostheses are artificial devices designed to replace missing teeth and restore both function and aesthetics. These devices play a critical role in improving patients' quality of life by enabling them to eat, speak, and smile confidently.",
    lists1: [
      "Dentures: Removable prosthetics that replace entire arches of missing teeth. Complete dentures are used when all teeth are absent, while partial dentures replace specific missing teeth.",
      "Dental Bridges: Fixed prostheses that replace one or more missing teeth by anchoring to adjacent natural teeth, providing stability and restoring function.",
      "Dental Implants: Titanium posts surgically placed in the jawbone to support crowns or bridges, offering a permanent solution to tooth loss that closely resembles natural teeth.",
    ],
    text4: "Types of Dental Prostheses:",
    lists: [
      "Dentures: Removable prosthetics that replace all or some missing teeth.",
      "Dental Bridges: Fixed solutions that anchor to adjacent teeth, filling gaps.",
      "Dental Implants: Titanium posts embedded in the jaw, supporting crowns or bridges.",
    ],
    text5: "Benefits:",
    lists2: [
      "Improved chewing and speaking abilities, allowing for a more enjoyable eating experience.",
      "Enhanced facial aesthetics and self-esteem by restoring the natural smile, positively impacting social interactions.",
      "Long-term solutions for missing teeth, particularly with the stability and permanence of implants, which help maintain jawbone health.",
    ],
    text6: "Recovery and Aftercare:",
    lists3: [
        "For implants, a soft diet during healing ensures success.",
      "Routine cleaning and occasional adjustments help maintain function and comfort."
    ],
    text7: "Dental Prostheses Healing Stages",
    text8: "The healing process for dental prostheses, particularly after the placement of dentures, bridges, or implants, involves stages that vary depending on the type of prosthetic used.",
    lists4: [
      {
        stage: "Initial Fitting",
        description: "The dentist fits the prosthesis, ensuring comfort and proper alignment.",
        duration: "1-2 hours",
      },
      {
        stage: "Adjustment Period",
        description: "Patients may need several adjustments as they become accustomed to the prosthesis.",
        duration: "1-2 weeks",
      },
      {
        stage: "Bone Integration (for implants)",
        description: "In the case of implants, the titanium posts integrate with the jawbone during osseointegration.",
        duration: "3-6 months",
      },
      {
        stage: "Final Restoration",
        description: "Once healing is complete, the final restoration (crown, denture adjustment) is placed.",
        duration: "1-2 hours",
      },
    ],
  },
  {
    id: 7,
    img: b_m_7,
    img1: b_7,
    img2: ser_img_1,
    subtitle: "ray dent clinic",
    title: "Gummy smile",
    subtitle1: "TREATEAMENT TIME: 3-6 days",
    icon: icon_7,
    text: "",
    text2: "Achieving a Balanced Smile Through Gum Contouring",
    text3: "Gummy smile targets the reduction of excessive gum visibility, enhancing both the balance and aesthetics of the smile. Treatments are carefully tailored to contour gum tissue, creating a proportional and attractive smile that highlights the teeth.",
    lists1: [
      "Excessive Gum Tissue: Creates an uneven appearance by showing too much gum when smiling.",
      "Uneven Gum Line: Results in an asymmetrical smile due to irregular gum tissue levels.",
      "Small Teeth Appearance: A high gum line may make teeth appear shorter than they are.",
    ],
    text4: "Types of Gummy Smile Treatments:",
    lists: [
      "Laser Gum Contouring: A minimally invasive procedure that reshapes the gum line for an instantly enhanced look.",
      "Crown Lengthening: Removes excess gum and bone tissue to expose more of the tooth surface, creating a balanced smile.",
      "Orthodontic Solutions: Adjusts jaw or teeth positioning for cases where gummy smiles are related to jaw structure.",
    ],
    text5: "Benefits:",
    lists2: [
      "Achieves a well-proportioned smile with better tooth-to-gum balance.",
      "Increases self-confidence, making patients feel more comfortable showing their smiles.",
      "Provides quick results with minimal discomfort, especially when using laser treatments.",
    ],
    text6: "Recovery and Aftercare:",
    lists3: [
      "Soft foods are recommended during the initial healing phase to minimize irritation.",
      "Regular oral hygiene practices help maintain gum health and aesthetic results.",
    ],
    text7: "Gummy Smile Healing Stages",
    text8: "The healing process after gummy smile can vary based on the specific procedure performed, such as laser contouring or crown lengthening. Recovery generally involves tissue healing and gradual adjustment to the new gum line. Following specific dietary and hygiene guidelines can help support optimal healing.",
    lists4: [
      {
        stage: "Procedure",
        description: "Excess gum tissue is removed or reshaped using laser or surgical tools.",
        duration: "30 minutes - 1 hour",
      },
      {
        stage: "Initial Recovery",
        description: "Swelling and minor soreness may occur; patients are advised to avoid vigorous brushing.",
        duration: "1-3 days",
      },
      {
        stage: "Soft Tissue Healing",
        description: "Gums begin to heal, with mild swelling or sensitivity typically subsiding.",
        duration: "1-2 weeks",
      },
      {
        stage: "Gum Stabilization",
        description: "The gum line stabilizes as new contours become permanent.",
        duration: "3-4 weeks",
      },
      {
        stage: "Full Recovery",
        description: "Complete healing, with patients enjoying a balanced, improved smile.",
        duration: "4-6 weeks",
      },
    ],
  },
];
